@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-white {
    background: linear-gradient(to bottom, transparent, #e1e7f0, #eaedf4e3, #f1f3f7, #f9f9fb, #ffffff);

}

.home-banner {
    background: url('../public/assets/images/helpcenterbannerOS.png');
    background-repeat: no-repeat;
    background-size: center;
    /* background-position: center center; */
}